<template>
  <div class="a-detail" v-loadingx="loading">
    <div class="s-img" v-if="imgSrc">
      <img :src="imgSrc">
    </div>
    <div class="go-bak">
      <fm-btn @click="showDetail=!showDetail" v-if="khstatus === 'over'">{{showDetail ? '关闭展示' : '展示结果'}}</fm-btn>
      <fm-btn @click="gobak">返回</fm-btn>
    </div>
    <div class="s-content">
      <div class="top-c">
        <div class="s-log">
          <img src="/static/logo/pink-logo.png">
        </div>
        <div class="s-title">{{appraisalData ? appraisalData.title : ''}}</div>
        <div class="s-remark">{{appraisalData ? appraisalData.remark : ''}}</div>
      </div>
      <div class="center-c">
        <!-- <div class="s-note">{{note}}</div> -->
        <template v-if="khstatus === 'waitDoing'">
          <div class="g-item" v-for="groupItem in showGroups" :key="groupItem.id">
            <div class="title">{{groupItem.name + '/' +groupItem.typeText}}</div>
            <div class="g-c">
              <div class="d-item" v-for="item in groupItem.detailList" :key="item.id">
                {{item.userName}}
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="khstatus === 'doing'">
          <div class="bkh-item" v-for="item in bkhList['doing']" :key="item.id">
            <div class="bkh-title" v-if="item.represent">代表单位：{{item.represent}}</div>
            <div class="bkh-title">演讲人：{{item.userName}}</div>
            <div class="bkh-note">{{item.remark}}</div>
          </div>
        </template>
        <template v-else>
          <span class="qr-note" style="font-size: 30px;color: #fff;">本次活动已结束</span>
        </template>
      </div>
      <div class="bottom-c">
        <div class="s-qr">
          <qrcode qrWidth="160px" :qrUrl="qrUrl" v-if="qrUrl"></qrcode>
          <span class="qr-note">扫码进入评选会场</span>
          <div class="other">
            <div class="qr-note">时间： {{appraisalData && appraisalData.planStartTime ? appraisalData.planStartTime : '暂无'}}</div>
            <div class="qr-note">地点： {{appraisalData && appraisalData.address ? appraisalData.address : '暂无'}}</div>
          </div>
        </div>
      </div>
      <div class="kh-jg" v-if="showDetail">
        <div>考核结果</div>
        <div style="width: 100%;height: 60%;">
          <fm-table-new
            @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'main')"
            :toolbox="showToolBox"
            export-file-name="考核结果"
            :columns="columns"
            :data-list="showBkhList"
            :stripe="false">
          </fm-table-new>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

import {
  appraisalRequest as request,
  appraisalGroupRequest,
  appraisalGroupDetailRequest
} from '../../api'

import {
  fileRequest
} from '@/api'

import Qrcode from '@/components/base/qrcode'

export default {
  components: {
    Qrcode
  },
  data () {
    let statusMap = {}
    store.getters.appraisalGroupDetailStatusList.forEach((v) => {
      statusMap[v.key] = []
    })
    return {
      showDetail: false,
      imgSrc: null,
      needLoad: false,
      loading: false,
      qrUrl: '',
      bkhList: statusMap,
      appraisalId: null,
      appraisalData: null,
      groups: [],
      sgIndex: 0,
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    showBkhList () {
      let data = []
      let keys = ['doing', 'ready', 'wait', 'over', 'giveup']
      keys.forEach(v => data = data.concat(this.bkhList[v]))
      data.sort((a, b) => b.value - a.value)
      return data
    },
    note () {
      let noteMap = {
        doing: '考核进行中',
        waitDoing: '考核即将开始，请您扫码进入会场。',
        over: '本次考核已结束，感谢您的参与。'
      }
      return noteMap[this.khstatus]
    },
    showGroups () {
      return this.groups.slice(this.sgIndex, this.sgIndex + 2)
    },
    khstatus () {
      let status = 'doing'
      if (this.bkhList['ready'].length === 0 && this.bkhList['doing'].length === 0 && this.bkhList['over'].length === 0) {
        status = 'waitDoing'
      }
      if (this.bkhList['doing'].length === 0 && this.bkhList['ready'].length === 0 && this.bkhList['wait'].length === 0) {
        status = 'over'
      }
      return status
    },
    khzList () {
      return this.bkhList['doing']
    },
    baseColumns () {
      let data = [{
        field: 'appraisalGroupName',
        title: '分组'
      },
      {
        field: 'userName',
        title: '被考核人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'value',
        title: '分值'
      },
      {
        field: 'ranking',
        title: '名次'
      },
      {
        field: 'gotta',
        title: '得奖'
      }]
      return data
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    },
    groupTypeList () {
      return this.$store.getters.appraisalGroupTypeList
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next()
    } else {
      next({name: 'appraisal.my'})
    }
  },
  created () {
    this.needLoad = true
    this.appraisalId = this.$router.history.current.query.id
    this.loadData()
    this.loadGroup()
    this.loadDetailList()
    this.timeOutLoad()
    this.timeOutShow()
  },
  beforeDestroy () {
    this.needLoad = false
  },
  methods: {
    async loadImg (fileId) {
      try {
        if (fileId) {
          const fileData = await fileRequest.download({
            id: fileId
          })
          this.imgSrc = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
        } else {
          this.imgSrc = null
        }
      } catch (e) {
        console.log(e)
      }
    },
    gobak () {
      this.$router.push({name: 'hrms.appraisal.my'})
    },
    timeOutShow () {
      if (this.needLoad) {
        setTimeout(() => {
          this.sgIndex = this.sgIndex += 2
          this.sgIndex = this.sgIndex > this.groups.length - 1 ? 0 : this.sgIndex
        }, 10000)
      }
    },
    timeOutLoad () {
      if (this.needLoad) {
        setTimeout(() => {
          this.loadDetailList()
          this.timeOutLoad()
        }, 2000)
      }
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
          this.qrUrl = window.location.origin + '/#/wap/' + (this.appraisalData.type === 'scoring' ? 'checking' : this.appraisalData.type) + '?id=' + this.appraisalId
          this.loadImg(this.appraisalData.screenImgId)
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadGroup () {
      let data = await appraisalGroupRequest.get({appraisalId: this.appraisalId})
      let dataMap = {}
      let details = await appraisalGroupDetailRequest.get({
        appraisalId: this.appraisalId
      })
      details.forEach(v => dataMap[v.appraisalGroupId] = dataMap[v.appraisalGroupId] ? dataMap[v.appraisalGroupId].concat(v) : [v])
      data.forEach((v) => {
        v.typeText = (this.groupTypeList.find(v1 => v1.key === v.type) || {label: ''}).label
        v.detailList = dataMap[v.id] || []
      })
      this.groups = data
    },
    loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        Object.keys(this.bkhList).forEach(v => this.bkhList[v] = [])
        data.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
        data.forEach((v) => {
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          this.bkhList[v.status].push(v)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bkh-item {
  .bkh-title {
    font-size: 45px;
    color: #fff;
    font-weight: 600;
    line-height: 71px;
  }
  .bkh-note {
    font-size: 16px;
    color: #fff;
    max-width: 50%;
    line-height: 32px;
  }
}
.center-c {
  margin-top: 10px;
  .g-item {
    margin-bottom: 35px;
    .g-c {
      display: flex;
    }
    .d-item {
      background: #F5F5F5;
      margin-right: 8px;
      color: rgba(0,0,0,0.65);
      font-size: 12px;
      border-radius: 2px;
      border: 1px solid #D9D9D9;
      line-height: 22px;
      padding: 0 8px;
    }
  }
  .title {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }
}
.top-c {
  .s-log {
    height: 40px;
    img {
      height: 100%;
    }
  }
  .s-title {
    font-size: 45px;
    line-height: 61px;
    margin: 10px 0;
    color: #fff;
    font-weight: 600;
  }
  .s-remark {
    font-size: 35px;
    line-height: 60px;
    color: #fff;
  }
}
.a-detail {
  background: #046081;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.s-img {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.s-content {
  z-index: 2;
  position: absolute;
  top: 95px;
  left: 50px;
  right: 50px;
  bottom: 70px;
}
.doing-note {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #999;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  padding: 2rem;
  margin: 1rem;
  line-height: 2rem;
  background: #eee;
  .bkhr-btns {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
.bkhr-info {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s-c {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.jg {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.go-bak {
  z-index: 3;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.bottom-c {
  position: absolute;
  bottom: 0;
  left: 0;
}
.s-qr {
  .qr-note {
    font-size: 16px;
    color: #fff;
  }
  .other {
    position: absolute;
    bottom: 30px;
    line-height: 30px;
    left: 200px;
    width: 300px;
  }
}
.kh-jg {
  position: absolute;
  width: 40%;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 5px;
  padding: 1rem 2rem;
}
</style>